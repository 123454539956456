
module.exports = {
    paths: {
        'jquery/metadata': 'jquery/jquery.metadata',
        'waypoints': 'waypoints/src/waypoint',
        'moment': 'moment/moment',
        // 'jquery/jquery.cookie': 'jquery.cookie',
        'knockout': 'ko',
        'ylxmasonry': 'masonry-layout/masonry',
        'ylx/isotope': 'isotope-layout/js/isotope'
    },
    shim: {
        'mage/trim-input': ['jquery-ui-modules/widget'],
        'jquery/validate': ['jquery/metadata'],
        // 'js-storage/js.storage': ['js-cookie/cookie-wrapper'],
        jquery: {
            exports: [
                '$',
                'jQuery'
            ]
        },
        underscore: {
            exports: '_'
        },
        ko: {
            deps: ['jquery'],
            exports: 'ko'
        },
        stripejs: {
            exports: 'Stripe'
        },
        'StripeIntegration_Payments/js/stripe_payments_express': ['stripe_payments'],
        'stripe_payments_express': ['stripe_payments']
    },
    "deps":
    [
        "Magento_Theme/js/theme"
    ]
};
