module.exports = async function(component){
switch(component){
case "mage/bootstrap":return import(/* webpackChunkName: "mage/bootstrap" */ "mage/bootstrap");
case "mage/dataPost":return import(/* webpackChunkName: "mage/dataPost" */ "mage/dataPost");
case "mage/common":return import(/* webpackChunkName: "mage/common" */ "mage/common");
case "Magento_Theme/js/theme":return import(/* webpackChunkName: "Magento_Theme/js/theme" */ "Magento_Theme/js/theme");
case "Magento_Theme/js/responsive":return import(/* webpackChunkName: "Magento_Theme/js/responsive" */ "Magento_Theme/js/responsive");
case "socialProvider":return import(/* webpackChunkName: "Mageplaza_SocialLogin/js/provider" */ "Mageplaza_SocialLogin/js/provider");
case "socialPopupForm":return import(/* webpackChunkName: "Mageplaza_SocialLogin/js/popup" */ "Mageplaza_SocialLogin/js/popup");
case "mageplaza/core/jquery/popup":return import(/* webpackChunkName: "Mageplaza_Core/js/jquery.magnific-popup.min" */ "Mageplaza_Core/js/jquery.magnific-popup.min");
case "mageplaza/core/owl.carousel":return import(/* webpackChunkName: "Mageplaza_Core/js/owl.carousel.min" */ "Mageplaza_Core/js/owl.carousel.min");
case "mageplaza/core/bootstrap":return import(/* webpackChunkName: "Mageplaza_Core/js/bootstrap.min" */ "Mageplaza_Core/js/bootstrap.min");
case "mpIonRangeSlider":return import(/* webpackChunkName: "Mageplaza_Core/js/ion.rangeSlider.min" */ "Mageplaza_Core/js/ion.rangeSlider.min");
case "touchPunch":return import(/* webpackChunkName: "Mageplaza_Core/js/jquery.ui.touch-punch.min" */ "Mageplaza_Core/js/jquery.ui.touch-punch.min");
case "mpDevbridgeAutocomplete":return import(/* webpackChunkName: "Mageplaza_Core/js/jquery.autocomplete.min" */ "Mageplaza_Core/js/jquery.autocomplete.min");
case "cardinaljsSandbox":return import(/* webpackChunkName: "cardinaljsSandbox" */ "cardinaljsSandbox");
case "cardinaljs":return import(/* webpackChunkName: "cardinaljs" */ "cardinaljs");
case "jquery/jquery-storageapi":return import(/* webpackChunkName: "js-storage/storage-wrapper" */ "js-storage/storage-wrapper");
case "jquery/validate":return import(/* webpackChunkName: "jquery/jquery.validate" */ "jquery/jquery.validate");
case "jquery/file-uploader":return import(/* webpackChunkName: "jquery/fileUploader/jquery.fileuploader" */ "jquery/fileUploader/jquery.fileuploader");
case "prototype":return import(/* webpackChunkName: "legacy-build.min" */ "legacy-build.min");
case "text":return import(/* webpackChunkName: "mage/requirejs/text" */ "mage/requirejs/text");
case "domReady":return import(/* webpackChunkName: "/home/bsk/Data/Projects/NodeJS/DevHelperModules/src/plugins/magento/loaders/domReadyWeb" */ "/home/bsk/Data/Projects/NodeJS/DevHelperModules/src/plugins/magento/loaders/domReadyWeb");
case "spectrum":return import(/* webpackChunkName: "jquery/spectrum/spectrum" */ "jquery/spectrum/spectrum");
case "tinycolor":return import(/* webpackChunkName: "jquery/spectrum/tinycolor" */ "jquery/spectrum/tinycolor");
case "mpAjax":return import(/* webpackChunkName: "YLX_AjaxLayer/js/view/layer" */ "YLX_AjaxLayer/js/view/layer");
case "quickSearch":return import(/* webpackChunkName: "Smile_ElasticsuiteCore/js/form-mini" */ "Smile_ElasticsuiteCore/js/form-mini");
case "rangeSlider":return import(/* webpackChunkName: "Smile_ElasticsuiteCatalog/js/range-slider-widget" */ "Smile_ElasticsuiteCatalog/js/range-slider-widget");
case "priceBundle":return import(/* webpackChunkName: "Magento_Bundle/js/price-bundle" */ "Magento_Bundle/js/price-bundle");
case "slide":return import(/* webpackChunkName: "Magento_Bundle/js/slide" */ "Magento_Bundle/js/slide");
case "productSummary":return import(/* webpackChunkName: "Magento_Bundle/js/product-summary" */ "Magento_Bundle/js/product-summary");
case "captcha":return import(/* webpackChunkName: "Magento_Captcha/js/captcha" */ "Magento_Captcha/js/captcha");
case "Magento_Captcha/captcha":return import(/* webpackChunkName: "Magento_Captcha/js/captcha" */ "Magento_Captcha/js/captcha");
case "priceBox":return import(/* webpackChunkName: "Magento_Catalog/js/price-box" */ "Magento_Catalog/js/price-box");
case "priceOptionDate":return import(/* webpackChunkName: "Magento_Catalog/js/price-option-date" */ "Magento_Catalog/js/price-option-date");
case "priceOptionFile":return import(/* webpackChunkName: "Magento_Catalog/js/price-option-file" */ "Magento_Catalog/js/price-option-file");
case "priceOptions":return import(/* webpackChunkName: "Magento_Catalog/js/price-options" */ "Magento_Catalog/js/price-options");
case "priceUtils":return import(/* webpackChunkName: "Magento_Catalog/js/price-utils" */ "Magento_Catalog/js/price-utils");
case "compareList":return import(/* webpackChunkName: "Magento_Catalog/js/list" */ "Magento_Catalog/js/list");
case "relatedProducts":return import(/* webpackChunkName: "Magento_Catalog/js/related-products" */ "Magento_Catalog/js/related-products");
case "upsellProducts":return import(/* webpackChunkName: "Magento_Catalog/js/upsell-products" */ "Magento_Catalog/js/upsell-products");
case "productListToolbarForm":return import(/* webpackChunkName: "Magento_Catalog/js/product/list/toolbar" */ "Magento_Catalog/js/product/list/toolbar");
case "catalogGallery":return import(/* webpackChunkName: "Magento_Catalog/js/gallery" */ "Magento_Catalog/js/gallery");
case "discountCode":return import(/* webpackChunkName: "Magento_Checkout/js/discount-codes" */ "Magento_Checkout/js/discount-codes");
case "regionUpdater":return import(/* webpackChunkName: "Magento_Checkout/js/region-updater" */ "Magento_Checkout/js/region-updater");
case "sidebar":return import(/* webpackChunkName: "Magento_Checkout/js/sidebar" */ "Magento_Checkout/js/sidebar");
case "configurable":return import(/* webpackChunkName: "Magento_ConfigurableProduct/js/configurable" */ "Magento_ConfigurableProduct/js/configurable");
case "requireCookie":return import(/* webpackChunkName: "Magento_Cookie/js/require-cookie" */ "Magento_Cookie/js/require-cookie");
case "cookieNotices":return import(/* webpackChunkName: "Magento_Cookie/js/notices" */ "Magento_Cookie/js/notices");
case "address":return import(/* webpackChunkName: "Magento_Customer/js/address" */ "Magento_Customer/js/address");
case "changeEmailPassword":return import(/* webpackChunkName: "Magento_Customer/js/change-email-password" */ "Magento_Customer/js/change-email-password");
case "passwordStrengthIndicator":return import(/* webpackChunkName: "Magento_Customer/js/password-strength-indicator" */ "Magento_Customer/js/password-strength-indicator");
case "zxcvbn":return import(/* webpackChunkName: "Magento_Customer/js/zxcvbn" */ "Magento_Customer/js/zxcvbn");
case "addressValidation":return import(/* webpackChunkName: "Magento_Customer/js/addressValidation" */ "Magento_Customer/js/addressValidation");
case "showPassword":return import(/* webpackChunkName: "Magento_Customer/js/show-password" */ "Magento_Customer/js/show-password");
case "downloadable":return import(/* webpackChunkName: "Magento_Downloadable/js/downloadable" */ "Magento_Downloadable/js/downloadable");
case "Magento_Downloadable/downloadable":return import(/* webpackChunkName: "Magento_Downloadable/js/downloadable" */ "Magento_Downloadable/js/downloadable");
case "giftOptions":return import(/* webpackChunkName: "Magento_GiftMessage/js/gift-options" */ "Magento_GiftMessage/js/gift-options");
case "Magento_GiftMessage/gift-options":return import(/* webpackChunkName: "Magento_GiftMessage/js/gift-options" */ "Magento_GiftMessage/js/gift-options");
case "subscriptionStatusResolver":return import(/* webpackChunkName: "Magento_Newsletter/js/subscription-status-resolver" */ "Magento_Newsletter/js/subscription-status-resolver");
case "newsletterSignUp":return import(/* webpackChunkName: "Magento_Newsletter/js/newsletter-sign-up" */ "Magento_Newsletter/js/newsletter-sign-up");
case "pageCache":return import(/* webpackChunkName: "Magento_PageCache/js/page-cache" */ "Magento_PageCache/js/page-cache");
case "creditCardType":return import(/* webpackChunkName: "Magento_Payment/js/cc-type" */ "Magento_Payment/js/cc-type");
case "loadPlayer":return import(/* webpackChunkName: "Magento_ProductVideo/js/load-player" */ "Magento_ProductVideo/js/load-player");
case "fotoramaVideoEvents":return import(/* webpackChunkName: "Magento_ProductVideo/js/fotorama-add-video-events" */ "Magento_ProductVideo/js/fotorama-add-video-events");
case "recentlyViewedProducts":return import(/* webpackChunkName: "Magento_Reports/js/recently-viewed" */ "Magento_Reports/js/recently-viewed");
case "giftMessage":return import(/* webpackChunkName: "Magento_Sales/js/gift-message" */ "Magento_Sales/js/gift-message");
case "ordersReturns":return import(/* webpackChunkName: "Magento_Sales/js/orders-returns" */ "Magento_Sales/js/orders-returns");
case "Magento_Sales/gift-message":return import(/* webpackChunkName: "Magento_Sales/js/gift-message" */ "Magento_Sales/js/gift-message");
case "Magento_Sales/orders-returns":return import(/* webpackChunkName: "Magento_Sales/js/orders-returns" */ "Magento_Sales/js/orders-returns");
case "Magento_Search/form-mini":return import(/* webpackChunkName: "Magento_Search/js/form-mini" */ "Magento_Search/js/form-mini");
case "escaper":return import(/* webpackChunkName: "Magento_Security/js/escaper" */ "Magento_Security/js/escaper");
case "Magento_SendFriend/back-event":return import(/* webpackChunkName: "Magento_SendFriend/js/back-event" */ "Magento_SendFriend/js/back-event");
case "ko":return import(/* webpackChunkName: "knockoutjs/knockout" */ "knockoutjs/knockout");
case "knockout":return import(/* webpackChunkName: "knockoutjs/knockout" */ "knockoutjs/knockout");
case "mageUtils":return import(/* webpackChunkName: "mage/utils/main" */ "mage/utils/main");
case "rjsResolver":return import(/* webpackChunkName: "/home/bsk/Data/Projects/NodeJS/DevHelperModules/src/plugins/magento/mage/resolver" */ "/home/bsk/Data/Projects/NodeJS/DevHelperModules/src/plugins/magento/mage/resolver");
case "rowBuilder":return import(/* webpackChunkName: "Magento_Theme/js/row-builder" */ "Magento_Theme/js/row-builder");
case "toggleAdvanced":return import(/* webpackChunkName: "mage/toggle" */ "mage/toggle");
case "translateInline":return import(/* webpackChunkName: "mage/translate-inline" */ "mage/translate-inline");
case "sticky":return import(/* webpackChunkName: "mage/sticky" */ "mage/sticky");
case "tabs":return import(/* webpackChunkName: "mage/tabs" */ "mage/tabs");
case "collapsible":return import(/* webpackChunkName: "mage/collapsible" */ "mage/collapsible");
case "dropdownDialog":return import(/* webpackChunkName: "mage/dropdown" */ "mage/dropdown");
case "dropdown":return import(/* webpackChunkName: "mage/dropdowns" */ "mage/dropdowns");
case "accordion":return import(/* webpackChunkName: "mage/accordion" */ "mage/accordion");
case "loader":return import(/* webpackChunkName: "mage/loader" */ "mage/loader");
case "tooltip":return import(/* webpackChunkName: "mage/tooltip" */ "mage/tooltip");
case "deletableItem":return import(/* webpackChunkName: "mage/deletable-item" */ "mage/deletable-item");
case "itemTable":return import(/* webpackChunkName: "mage/item-table" */ "mage/item-table");
case "fieldsetControls":return import(/* webpackChunkName: "mage/fieldset-controls" */ "mage/fieldset-controls");
case "fieldsetResetControl":return import(/* webpackChunkName: "mage/fieldset-controls" */ "mage/fieldset-controls");
case "redirectUrl":return import(/* webpackChunkName: "mage/redirect-url" */ "mage/redirect-url");
case "loaderAjax":return import(/* webpackChunkName: "mage/loader" */ "mage/loader");
case "menu":return import(/* webpackChunkName: "mage/menu" */ "mage/menu");
case "popupWindow":return import(/* webpackChunkName: "mage/popup-window" */ "mage/popup-window");
case "validation":return import(/* webpackChunkName: "mage/validation/validation" */ "mage/validation/validation");
case "breadcrumbs":return import(/* webpackChunkName: "Magento_Theme/js/view/breadcrumbs" */ "Magento_Theme/js/view/breadcrumbs");
case "jquery/ui":return import(/* webpackChunkName: "jquery/compat" */ "jquery/compat");
case "cookieStatus":return import(/* webpackChunkName: "Magento_Theme/js/cookie-status" */ "Magento_Theme/js/cookie-status");
case "mageTranslationDictionary":return import(/* webpackChunkName: "Magento_Translation/js/mage-translation-dictionary" */ "Magento_Translation/js/mage-translation-dictionary");
case "editTrigger":return import(/* webpackChunkName: "mage/edit-trigger" */ "mage/edit-trigger");
case "addClass":return import(/* webpackChunkName: "Magento_Translation/js/add-class" */ "Magento_Translation/js/add-class");
case "Magento_Translation/add-class":return import(/* webpackChunkName: "Magento_Translation/js/add-class" */ "Magento_Translation/js/add-class");
case "uiElement":return import(/* webpackChunkName: "Magento_Ui/js/lib/core/element/element" */ "Magento_Ui/js/lib/core/element/element");
case "uiCollection":return import(/* webpackChunkName: "Magento_Ui/js/lib/core/collection" */ "Magento_Ui/js/lib/core/collection");
case "uiComponent":return import(/* webpackChunkName: "Magento_Ui/js/lib/core/collection" */ "Magento_Ui/js/lib/core/collection");
case "uiClass":return import(/* webpackChunkName: "Magento_Ui/js/lib/core/class" */ "Magento_Ui/js/lib/core/class");
case "uiEvents":return import(/* webpackChunkName: "Magento_Ui/js/lib/core/events" */ "Magento_Ui/js/lib/core/events");
case "uiRegistry":return import(/* webpackChunkName: "Magento_Ui/js/lib/registry/registry" */ "Magento_Ui/js/lib/registry/registry");
case "consoleLogger":return import(/* webpackChunkName: "Magento_Ui/js/lib/logger/console-logger" */ "Magento_Ui/js/lib/logger/console-logger");
case "uiLayout":return import(/* webpackChunkName: "Magento_Ui/js/core/renderer/layout" */ "Magento_Ui/js/core/renderer/layout");
case "buttonAdapter":return import(/* webpackChunkName: "Magento_Ui/js/form/button-adapter" */ "Magento_Ui/js/form/button-adapter");
case "chartJs":return import(/* webpackChunkName: "chartjs/Chart.min" */ "chartjs/Chart.min");
case "wysiwygAdapter":return import(/* webpackChunkName: "mage/adminhtml/wysiwyg/tiny_mce/tinymce5Adapter" */ "mage/adminhtml/wysiwyg/tiny_mce/tinymce5Adapter");
case "taxToggle":return import(/* webpackChunkName: "Magento_Weee/js/tax-toggle" */ "Magento_Weee/js/tax-toggle");
case "Magento_Weee/tax-toggle":return import(/* webpackChunkName: "Magento_Weee/js/tax-toggle" */ "Magento_Weee/js/tax-toggle");
case "wishlist":return import(/* webpackChunkName: "Magento_Wishlist/js/wishlist" */ "Magento_Wishlist/js/wishlist");
case "addToWishlist":return import(/* webpackChunkName: "Magento_Wishlist/js/add-to-wishlist" */ "Magento_Wishlist/js/add-to-wishlist");
case "wishlistSearch":return import(/* webpackChunkName: "Magento_Wishlist/js/search" */ "Magento_Wishlist/js/search");
case "Mageplaza_Smtp/js/view/billing-address-mixins":return import(/* webpackChunkName: "Mageplaza_Smtp/js/view/billing-address-mixins" */ "Mageplaza_Smtp/js/view/billing-address-mixins");
case "Mageplaza_Smtp/js/view/shipping-mixins":return import(/* webpackChunkName: "Mageplaza_Smtp/js/view/shipping-mixins" */ "Mageplaza_Smtp/js/view/shipping-mixins");
case "Smile_ElasticsuiteCore/js/validation/validator-mixin":return import(/* webpackChunkName: "Smile_ElasticsuiteCore/js/validation/validator-mixin" */ "Smile_ElasticsuiteCore/js/validation/validator-mixin");
case "Magento_Catalog/js/product/breadcrumbs":return import(/* webpackChunkName: "Magento_Catalog/js/product/breadcrumbs" */ "Magento_Catalog/js/product/breadcrumbs");
case "Magento_Theme/js/view/add-home-breadcrumb":return import(/* webpackChunkName: "Magento_Theme/js/view/add-home-breadcrumb" */ "Magento_Theme/js/view/add-home-breadcrumb");
case "Magento_SalesRule/js/model/place-order-mixin":return import(/* webpackChunkName: "Magento_SalesRule/js/model/place-order-mixin" */ "Magento_SalesRule/js/model/place-order-mixin");
case "Magento_SalesRule/js/model/shipping-save-processor-mixin":return import(/* webpackChunkName: "Magento_SalesRule/js/model/shipping-save-processor-mixin" */ "Magento_SalesRule/js/model/shipping-save-processor-mixin");
case "Magento_Swatches/js/swatch-renderer-mixin":return import(/* webpackChunkName: "Magento_Swatches/js/swatch-renderer-mixin" */ "Magento_Swatches/js/swatch-renderer-mixin");
case "Mageplaza_GoogleRecaptcha/js/captcha-mixin":return import(/* webpackChunkName: "Mageplaza_GoogleRecaptcha/js/captcha-mixin" */ "Mageplaza_GoogleRecaptcha/js/captcha-mixin");
case "jquery/jquery-migrate":return import(/* webpackChunkName: "jquery/jquery-migrate" */ "jquery/jquery-migrate");
case "app":return import(/* webpackChunkName: "/home/bsk/Data/Projects/NodeJS/DevHelperModules/src/plugins/magento/mage/app" */ "/home/bsk/Data/Projects/NodeJS/DevHelperModules/src/plugins/magento/mage/app");
case "knockoutjs/knockout-es5":return import(/* webpackChunkName: "knockoutjs/knockout-es5" */ "knockoutjs/knockout-es5");
case "knockoutjs/knockout-repeat":return import(/* webpackChunkName: "knockoutjs/knockout-repeat" */ "knockoutjs/knockout-repeat");
case "knockoutjs/knockout-fast-foreach":return import(/* webpackChunkName: "knockoutjs/knockout-fast-foreach" */ "knockoutjs/knockout-fast-foreach");
case "waypoints":return import(/* webpackChunkName: "waypoints/src/waypoint" */ "waypoints/src/waypoint");
case "moment":return import(/* webpackChunkName: "moment/moment" */ "moment/moment");
case "jquery/jquery.cookie":return import(/* webpackChunkName: "js-cookie/cookie-wrapper" */ "js-cookie/cookie-wrapper");
case "ylxmasonry":return import(/* webpackChunkName: "masonry-layout/masonry" */ "masonry-layout/masonry");
case "ylx/isotope":return import(/* webpackChunkName: "isotope-layout/js/isotope" */ "isotope-layout/js/isotope");
case "jquery":return import(/* webpackChunkName: "jquery" */ "jquery");
case "ylx/owlcarousel":return import(/* webpackChunkName: "YLX_YlxTheme/js/owl.carousel.min" */ "YLX_YlxTheme/js/owl.carousel.min");
case "js/theme":return import(/* webpackChunkName: "js/theme" */ "js/theme");
case "jquery-ui-modules/core":return import(/* webpackChunkName: "jquery/ui-modules/core" */ "jquery/ui-modules/core");
case "jquery-ui-modules/widget":return import(/* webpackChunkName: "jquery/ui-modules/widget" */ "jquery/ui-modules/widget");
case "domReady!":return import(/* webpackChunkName: "domReady!" */ "domReady!");
case "mage/smart-keyboard-handler":return import(/* webpackChunkName: "mage/smart-keyboard-handler" */ "mage/smart-keyboard-handler");
case "mage/mage":return import(/* webpackChunkName: "mage/mage" */ "mage/mage");
case "mage/ie-class-fixer":return import(/* webpackChunkName: "mage/ie-class-fixer" */ "mage/ie-class-fixer");
case "mage/apply/main":return import(/* webpackChunkName: "mage/apply/main" */ "mage/apply/main");
case "js/bootstrap":return import(/* webpackChunkName: "js/bootstrap" */ "js/bootstrap");
case "mage/cookies":return import(/* webpackChunkName: "mage/cookies" */ "mage/cookies");
case "text!js-translation.json":return import(/* webpackChunkName: "text!js-translation.json" */ "text!js-translation.json");
case "underscore":return import(/* webpackChunkName: "underscore" */ "underscore");
case "mage/apply/scripts":return import(/* webpackChunkName: "mage/apply/scripts" */ "mage/apply/scripts");
case "bootstrap/js/src/modal":return import(/* webpackChunkName: "bootstrap/js/src/modal" */ "bootstrap/js/src/modal");
case "bootstrap/js/src/offcanvas":return import(/* webpackChunkName: "bootstrap/js/src/offcanvas" */ "bootstrap/js/src/offcanvas");
case "bootstrap/js/src/collapse":return import(/* webpackChunkName: "bootstrap/js/src/collapse" */ "bootstrap/js/src/collapse");
case "matchMedia":return import(/* webpackChunkName: "matchMedia" */ "matchMedia");
case "mage/tabs":return import(/* webpackChunkName: "mage/tabs" */ "mage/tabs");
case "mage/collapsible":return import(/* webpackChunkName: "mage/collapsible" */ "mage/collapsible");
case "mage/template":return import(/* webpackChunkName: "mage/template" */ "mage/template");
case "Magento_Ui/js/modal/confirm":return import(/* webpackChunkName: "Magento_Ui/js/modal/confirm" */ "Magento_Ui/js/modal/confirm");
case "Magento_Ui/js/lib/knockout/bootstrap":return import(/* webpackChunkName: "Magento_Ui/js/lib/knockout/bootstrap" */ "Magento_Ui/js/lib/knockout/bootstrap");
case "mage/translate":return import(/* webpackChunkName: "mage/translate" */ "mage/translate");
case "Magento_Ui/js/modal/modal":return import(/* webpackChunkName: "Magento_Ui/js/modal/modal" */ "Magento_Ui/js/modal/modal");
case "text!ui/template/modal/modal-popup.html":return import(/* webpackChunkName: "text!ui/template/modal/modal-popup.html" */ "text!ui/template/modal/modal-popup.html");
case "text!ui/template/modal/modal-slide.html":return import(/* webpackChunkName: "text!ui/template/modal/modal-slide.html" */ "text!ui/template/modal/modal-slide.html");
case "text!ui/template/modal/modal-custom.html":return import(/* webpackChunkName: "text!ui/template/modal/modal-custom.html" */ "text!ui/template/modal/modal-custom.html");
case "Magento_Ui/js/lib/key-codes":return import(/* webpackChunkName: "Magento_Ui/js/lib/key-codes" */ "Magento_Ui/js/lib/key-codes");
case "Magento_Ui/js/lib/knockout/template/engine":return import(/* webpackChunkName: "Magento_Ui/js/lib/knockout/template/engine" */ "Magento_Ui/js/lib/knockout/template/engine");
case "Magento_Ui/js/lib/knockout/bindings/bootstrap":return import(/* webpackChunkName: "Magento_Ui/js/lib/knockout/bindings/bootstrap" */ "Magento_Ui/js/lib/knockout/bindings/bootstrap");
case "Magento_Ui/js/lib/knockout/extender/observable_array":return import(/* webpackChunkName: "Magento_Ui/js/lib/knockout/extender/observable_array" */ "Magento_Ui/js/lib/knockout/extender/observable_array");
case "Magento_Ui/js/lib/knockout/extender/bound-nodes":return import(/* webpackChunkName: "Magento_Ui/js/lib/knockout/extender/bound-nodes" */ "Magento_Ui/js/lib/knockout/extender/bound-nodes");
case "Magento_Ui/js/lib/knockout/template/observable_source":return import(/* webpackChunkName: "Magento_Ui/js/lib/knockout/template/observable_source" */ "Magento_Ui/js/lib/knockout/template/observable_source");
case "Magento_Ui/js/lib/knockout/template/renderer":return import(/* webpackChunkName: "Magento_Ui/js/lib/knockout/template/renderer" */ "Magento_Ui/js/lib/knockout/template/renderer");
case "Magento_Ui/js/lib/logger/console-logger":return import(/* webpackChunkName: "Magento_Ui/js/lib/logger/console-logger" */ "Magento_Ui/js/lib/logger/console-logger");
case "Magento_Ui/js/lib/knockout/bindings/resizable":return import(/* webpackChunkName: "Magento_Ui/js/lib/knockout/bindings/resizable" */ "Magento_Ui/js/lib/knockout/bindings/resizable");
case "Magento_Ui/js/lib/knockout/bindings/i18n":return import(/* webpackChunkName: "Magento_Ui/js/lib/knockout/bindings/i18n" */ "Magento_Ui/js/lib/knockout/bindings/i18n");
case "Magento_Ui/js/lib/knockout/bindings/scope":return import(/* webpackChunkName: "Magento_Ui/js/lib/knockout/bindings/scope" */ "Magento_Ui/js/lib/knockout/bindings/scope");
case "Magento_Ui/js/lib/knockout/bindings/range":return import(/* webpackChunkName: "Magento_Ui/js/lib/knockout/bindings/range" */ "Magento_Ui/js/lib/knockout/bindings/range");
case "Magento_Ui/js/lib/knockout/bindings/mage-init":return import(/* webpackChunkName: "Magento_Ui/js/lib/knockout/bindings/mage-init" */ "Magento_Ui/js/lib/knockout/bindings/mage-init");
case "Magento_Ui/js/lib/knockout/bindings/keyboard":return import(/* webpackChunkName: "Magento_Ui/js/lib/knockout/bindings/keyboard" */ "Magento_Ui/js/lib/knockout/bindings/keyboard");
case "Magento_Ui/js/lib/knockout/bindings/optgroup":return import(/* webpackChunkName: "Magento_Ui/js/lib/knockout/bindings/optgroup" */ "Magento_Ui/js/lib/knockout/bindings/optgroup");
case "Magento_Ui/js/lib/knockout/bindings/after-render":return import(/* webpackChunkName: "Magento_Ui/js/lib/knockout/bindings/after-render" */ "Magento_Ui/js/lib/knockout/bindings/after-render");
case "Magento_Ui/js/lib/knockout/bindings/autoselect":return import(/* webpackChunkName: "Magento_Ui/js/lib/knockout/bindings/autoselect" */ "Magento_Ui/js/lib/knockout/bindings/autoselect");
case "Magento_Ui/js/lib/knockout/bindings/datepicker":return import(/* webpackChunkName: "Magento_Ui/js/lib/knockout/bindings/datepicker" */ "Magento_Ui/js/lib/knockout/bindings/datepicker");
case "Magento_Ui/js/lib/knockout/bindings/outer_click":return import(/* webpackChunkName: "Magento_Ui/js/lib/knockout/bindings/outer_click" */ "Magento_Ui/js/lib/knockout/bindings/outer_click");
case "Magento_Ui/js/lib/knockout/bindings/fadeVisible":return import(/* webpackChunkName: "Magento_Ui/js/lib/knockout/bindings/fadeVisible" */ "Magento_Ui/js/lib/knockout/bindings/fadeVisible");
case "Magento_Ui/js/lib/knockout/bindings/collapsible":return import(/* webpackChunkName: "Magento_Ui/js/lib/knockout/bindings/collapsible" */ "Magento_Ui/js/lib/knockout/bindings/collapsible");
case "Magento_Ui/js/lib/knockout/bindings/staticChecked":return import(/* webpackChunkName: "Magento_Ui/js/lib/knockout/bindings/staticChecked" */ "Magento_Ui/js/lib/knockout/bindings/staticChecked");
case "Magento_Ui/js/lib/knockout/bindings/simple-checked":return import(/* webpackChunkName: "Magento_Ui/js/lib/knockout/bindings/simple-checked" */ "Magento_Ui/js/lib/knockout/bindings/simple-checked");
case "Magento_Ui/js/lib/knockout/bindings/bind-html":return import(/* webpackChunkName: "Magento_Ui/js/lib/knockout/bindings/bind-html" */ "Magento_Ui/js/lib/knockout/bindings/bind-html");
case "Magento_Ui/js/lib/knockout/bindings/tooltip":return import(/* webpackChunkName: "Magento_Ui/js/lib/knockout/bindings/tooltip" */ "Magento_Ui/js/lib/knockout/bindings/tooltip");
case "Magento_Ui/js/lib/knockout/bindings/color-picker":return import(/* webpackChunkName: "Magento_Ui/js/lib/knockout/bindings/color-picker" */ "Magento_Ui/js/lib/knockout/bindings/color-picker");
case "mage/utils/arrays":return import(/* webpackChunkName: "mage/utils/arrays" */ "mage/utils/arrays");
case "mage/utils/compare":return import(/* webpackChunkName: "mage/utils/compare" */ "mage/utils/compare");
case "mage/utils/misc":return import(/* webpackChunkName: "mage/utils/misc" */ "mage/utils/misc");
case "mage/utils/objects":return import(/* webpackChunkName: "mage/utils/objects" */ "mage/utils/objects");
case "mage/utils/strings":return import(/* webpackChunkName: "mage/utils/strings" */ "mage/utils/strings");
case "mage/utils/template":return import(/* webpackChunkName: "mage/utils/template" */ "mage/utils/template");
case "mage/utils/wrapper":return import(/* webpackChunkName: "mage/utils/wrapper" */ "mage/utils/wrapper");
case "Magento_Ui/js/lib/knockout/template/loader":return import(/* webpackChunkName: "Magento_Ui/js/lib/knockout/template/loader" */ "Magento_Ui/js/lib/knockout/template/loader");
case "Magento_Ui/js/lib/logger/logger":return import(/* webpackChunkName: "Magento_Ui/js/lib/logger/logger" */ "Magento_Ui/js/lib/logger/logger");
case "Magento_Ui/js/lib/logger/entry-factory":return import(/* webpackChunkName: "Magento_Ui/js/lib/logger/entry-factory" */ "Magento_Ui/js/lib/logger/entry-factory");
case "Magento_Ui/js/lib/logger/console-output-handler":return import(/* webpackChunkName: "Magento_Ui/js/lib/logger/console-output-handler" */ "Magento_Ui/js/lib/logger/console-output-handler");
case "Magento_Ui/js/lib/logger/formatter":return import(/* webpackChunkName: "Magento_Ui/js/lib/logger/formatter" */ "Magento_Ui/js/lib/logger/formatter");
case "Magento_Ui/js/lib/logger/message-pool":return import(/* webpackChunkName: "Magento_Ui/js/lib/logger/message-pool" */ "Magento_Ui/js/lib/logger/message-pool");
case "Magento_Ui/js/lib/logger/levels-pool":return import(/* webpackChunkName: "Magento_Ui/js/lib/logger/levels-pool" */ "Magento_Ui/js/lib/logger/levels-pool");
case "Magento_Ui/js/lib/core/storage/local":return import(/* webpackChunkName: "Magento_Ui/js/lib/core/storage/local" */ "Magento_Ui/js/lib/core/storage/local");
case "Magento_Ui/js/lib/logger/logger-utils":return import(/* webpackChunkName: "Magento_Ui/js/lib/logger/logger-utils" */ "Magento_Ui/js/lib/logger/logger-utils");
case "Magento_Ui/js/lib/logger/entry":return import(/* webpackChunkName: "Magento_Ui/js/lib/logger/entry" */ "Magento_Ui/js/lib/logger/entry");
case "Magento_Ui/js/lib/view/utils/async":return import(/* webpackChunkName: "Magento_Ui/js/lib/view/utils/async" */ "Magento_Ui/js/lib/view/utils/async");
case "jquery-ui-modules/resizable":return import(/* webpackChunkName: "jquery/ui-modules/widgets/resizable" */ "jquery/ui-modules/widgets/resizable");
case "module":return import(/* webpackChunkName: "module" */ "module");
case "text!ui/template/tooltip/tooltip.html":return import(/* webpackChunkName: "text!ui/template/tooltip/tooltip.html" */ "text!ui/template/tooltip/tooltip.html");
case "Magento_Ui/js/lib/view/utils/dom-observer":return import(/* webpackChunkName: "Magento_Ui/js/lib/view/utils/dom-observer" */ "Magento_Ui/js/lib/view/utils/dom-observer");
case "Magento_Ui/js/lib/view/utils/bindings":return import(/* webpackChunkName: "Magento_Ui/js/lib/view/utils/bindings" */ "Magento_Ui/js/lib/view/utils/bindings");
case "jquery-ui-modules/mouse":return import(/* webpackChunkName: "jquery/ui-modules/widgets/mouse" */ "jquery/ui-modules/widgets/mouse");
case "Magento_PageCache/js/form-key-provider":return import(/* webpackChunkName: "Magento_PageCache/js/form-key-provider" */ "Magento_PageCache/js/form-key-provider");
case "jquery-ui-modules/menu":return import(/* webpackChunkName: "jquery/ui-modules/widgets/menu" */ "jquery/ui-modules/widgets/menu");
case "jquery/jquery.mobile.custom":return import(/* webpackChunkName: "jquery/jquery.mobile.custom" */ "jquery/jquery.mobile.custom");
case "jquery-ui-modules/position":return import(/* webpackChunkName: "jquery/ui-modules/position" */ "jquery/ui-modules/position");
case "Magento_Catalog/js/price-utils":return import(/* webpackChunkName: "Magento_Catalog/js/price-utils" */ "Magento_Catalog/js/price-utils");
case "Magento_Search/js/form-mini":return import(/* webpackChunkName: "Magento_Search/js/form-mini" */ "Magento_Search/js/form-mini");
case "Magento_Ui/js/core/app":return import(/* webpackChunkName: "Magento_Ui/js/core/app" */ "Magento_Ui/js/core/app");
case "jquery-ui-modules/dialog":return import(/* webpackChunkName: "jquery/ui-modules/widgets/dialog" */ "jquery/ui-modules/widgets/dialog");
case "Magento_Ui/js/core/renderer/types":return import(/* webpackChunkName: "Magento_Ui/js/core/renderer/types" */ "Magento_Ui/js/core/renderer/types");
case "Magento_Ui/js/core/renderer/layout":return import(/* webpackChunkName: "Magento_Ui/js/core/renderer/layout" */ "Magento_Ui/js/core/renderer/layout");
case "jquery-ui-modules/button":return import(/* webpackChunkName: "jquery/ui-modules/widgets/button" */ "jquery/ui-modules/widgets/button");
case "mage/validation":return import(/* webpackChunkName: "mage/validation" */ "mage/validation");
case "jquery/jquery.metadata":return import(/* webpackChunkName: "jquery/jquery.metadata" */ "jquery/jquery.metadata");
case "mage/trim-input":return import(/* webpackChunkName: "mage/trim-input" */ "mage/trim-input");
case "Magento_Ui/js/block-loader":return import(/* webpackChunkName: "Magento_Ui/js/block-loader" */ "Magento_Ui/js/block-loader");
case "Magento_Catalog/js/view/compare-products":return import(/* webpackChunkName: "Magento_Catalog/js/view/compare-products" */ "Magento_Catalog/js/view/compare-products");
case "Magento_Theme/js/view/messages":return import(/* webpackChunkName: "Magento_Theme/js/view/messages" */ "Magento_Theme/js/view/messages");
case "text!ui/template/block-loader.html":return import(/* webpackChunkName: "text!ui/template/block-loader.html" */ "text!ui/template/block-loader.html");
case "Magento_Ui/js/lib/core/element/links":return import(/* webpackChunkName: "Magento_Ui/js/lib/core/element/links" */ "Magento_Ui/js/lib/core/element/links");
case "mage/url":return import(/* webpackChunkName: "mage/url" */ "mage/url");
case "mage/storage":return import(/* webpackChunkName: "mage/storage" */ "mage/storage");
case "Magento_Ui/js/form/form":return import(/* webpackChunkName: "Magento_Ui/js/form/form" */ "Magento_Ui/js/form/form");
case "mage/decorate":return import(/* webpackChunkName: "mage/decorate" */ "mage/decorate");
case "Magento_Ui/js/modal/alert":return import(/* webpackChunkName: "Magento_Ui/js/modal/alert" */ "Magento_Ui/js/modal/alert");
case "jquery-ui-modules/effect-fade":return import(/* webpackChunkName: "jquery/ui-modules/effects/effect-fade" */ "jquery/ui-modules/effects/effect-fade");
case "mage/dropdown":return import(/* webpackChunkName: "mage/dropdown" */ "mage/dropdown");
case "Magento_Ui/js/lib/spinner":return import(/* webpackChunkName: "Magento_Ui/js/lib/spinner" */ "Magento_Ui/js/lib/spinner");
case "Magento_Ui/js/form/adapter":return import(/* webpackChunkName: "Magento_Ui/js/form/adapter" */ "Magento_Ui/js/form/adapter");
case "Magento_Ui/js/model/messageList":return import(/* webpackChunkName: "Magento_Ui/js/model/messageList" */ "Magento_Ui/js/model/messageList");
case "Magento_Catalog/js/storage-manager":return import(/* webpackChunkName: "Magento_Catalog/js/storage-manager" */ "Magento_Catalog/js/storage-manager");
case "jquery-ui-modules/effect":return import(/* webpackChunkName: "jquery/ui-modules/effect" */ "jquery/ui-modules/effect");
case "Magento_Ui/js/form/adapter/buttons":return import(/* webpackChunkName: "Magento_Ui/js/form/adapter/buttons" */ "Magento_Ui/js/form/adapter/buttons");
case "Magento_Ui/js/model/messages":return import(/* webpackChunkName: "Magento_Ui/js/model/messages" */ "Magento_Ui/js/model/messages");
case "Magento_Catalog/js/product/storage/storage-service":return import(/* webpackChunkName: "Magento_Catalog/js/product/storage/storage-service" */ "Magento_Catalog/js/product/storage/storage-service");
case "Magento_Ui/js/view/messages":return import(/* webpackChunkName: "Magento_Ui/js/view/messages" */ "Magento_Ui/js/view/messages");
case "Magento_Catalog/js/product/storage/ids-storage":return import(/* webpackChunkName: "Magento_Catalog/js/product/storage/ids-storage" */ "Magento_Catalog/js/product/storage/ids-storage");
case "Magento_Catalog/js/product/storage/data-storage":return import(/* webpackChunkName: "Magento_Catalog/js/product/storage/data-storage" */ "Magento_Catalog/js/product/storage/data-storage");
case "Magento_Catalog/js/product/storage/ids-storage-compare":return import(/* webpackChunkName: "Magento_Catalog/js/product/storage/ids-storage-compare" */ "Magento_Catalog/js/product/storage/ids-storage-compare");
case "jquery-ui-modules/effect-blind":return import(/* webpackChunkName: "jquery/ui-modules/effects/effect-blind" */ "jquery/ui-modules/effects/effect-blind");
case "Magento_Catalog/js/product/query-builder":return import(/* webpackChunkName: "Magento_Catalog/js/product/query-builder" */ "Magento_Catalog/js/product/query-builder");
case "text!Magento_Ui/template/messages.html":return import(/* webpackChunkName: "text!Magento_Ui/template/messages.html" */ "text!Magento_Ui/template/messages.html");
case "Magento_Catalog/js/view/image":return import(/* webpackChunkName: "Magento_Catalog/js/view/image" */ "Magento_Catalog/js/view/image");
case "Magento_Captcha/js/model/captcha":return import(/* webpackChunkName: "Magento_Captcha/js/model/captcha" */ "Magento_Captcha/js/model/captcha");
case "Magento_Captcha/js/model/captchaList":return import(/* webpackChunkName: "Magento_Captcha/js/model/captchaList" */ "Magento_Captcha/js/model/captchaList");
case "text!ui/template/collection.html":return import(/* webpackChunkName: "text!ui/template/collection.html" */ "text!ui/template/collection.html");
case "Magento_Captcha/js/action/refresh":return import(/* webpackChunkName: "Magento_Captcha/js/action/refresh" */ "Magento_Captcha/js/action/refresh");
case "Mageplaza_SocialLogin/js/view/social-buttons":return import(/* webpackChunkName: "Mageplaza_SocialLogin/js/view/social-buttons" */ "Mageplaza_SocialLogin/js/view/social-buttons");
case "text!Mageplaza_SocialLogin/template/social-buttons.html":return import(/* webpackChunkName: "text!Mageplaza_SocialLogin/template/social-buttons.html" */ "text!Mageplaza_SocialLogin/template/social-buttons.html");
case "Magento_Swatches/js/swatch-renderer":return import(/* webpackChunkName: "Magento_Swatches/js/swatch-renderer" */ "Magento_Swatches/js/swatch-renderer");
case "Magento_Catalog/js/product/view/product-ids-resolver":return import(/* webpackChunkName: "Magento_Catalog/js/product/view/product-ids-resolver" */ "Magento_Catalog/js/product/view/product-ids-resolver");
case "Magento_Catalog/js/product/view/product-info-resolver":return import(/* webpackChunkName: "Magento_Catalog/js/product/view/product-info-resolver" */ "Magento_Catalog/js/product/view/product-info-resolver");
case "Smile_ElasticsuiteCatalog/js/slider":return import(/* webpackChunkName: "Smile_ElasticsuiteCatalog/js/slider" */ "Smile_ElasticsuiteCatalog/js/slider");
case "Smile_ElasticsuiteCatalog/js/jquery.ui.touch-punch.min":return import(/* webpackChunkName: "Smile_ElasticsuiteCatalog/js/jquery.ui.touch-punch.min" */ "Smile_ElasticsuiteCatalog/js/jquery.ui.touch-punch.min");
case "jquery/jquery.parsequery":return import(/* webpackChunkName: "jquery/jquery.parsequery" */ "jquery/jquery.parsequery");
case "mage/validation/validation":return import(/* webpackChunkName: "mage/validation/validation" */ "mage/validation/validation");
case "Magento_Catalog/js/product/view/product-ids":return import(/* webpackChunkName: "Magento_Catalog/js/product/view/product-ids" */ "Magento_Catalog/js/product/view/product-ids");
case "Magento_Catalog/js/product/view/product-info":return import(/* webpackChunkName: "Magento_Catalog/js/product/view/product-info" */ "Magento_Catalog/js/product/view/product-info");
case "Magento_Catalog/js/validate-product":return import(/* webpackChunkName: "Magento_Catalog/js/validate-product" */ "Magento_Catalog/js/validate-product");
case "magnifier/magnify":return import(/* webpackChunkName: "magnifier/magnify" */ "magnifier/magnify");
case "Magento_Theme/js/model/breadcrumb-list":return import(/* webpackChunkName: "Magento_Theme/js/model/breadcrumb-list" */ "Magento_Theme/js/model/breadcrumb-list");
case "text!Magento_Theme/templates/breadcrumbs.html":return import(/* webpackChunkName: "text!Magento_Theme/templates/breadcrumbs.html" */ "text!Magento_Theme/templates/breadcrumbs.html");
case "Magento_Catalog/product/view/validation":return import(/* webpackChunkName: "Magento_Catalog/product/view/validation" */ "Magento_Catalog/product/view/validation");
case "magnifier/magnifier":return import(/* webpackChunkName: "magnifier/magnifier" */ "magnifier/magnifier");
case "mage/gallery/gallery":return import(/* webpackChunkName: "mage/gallery/gallery" */ "mage/gallery/gallery");
case "Magento_ProductVideo/js/fotorama-add-video-events":return import(/* webpackChunkName: "Magento_ProductVideo/js/fotorama-add-video-events" */ "Magento_ProductVideo/js/fotorama-add-video-events");
case "Magento_Review/js/error-placement":return import(/* webpackChunkName: "Magento_Review/js/error-placement" */ "Magento_Review/js/error-placement");
case "Magento_Review/js/validate-review":return import(/* webpackChunkName: "Magento_Review/js/validate-review" */ "Magento_Review/js/validate-review");
case "Magento_Review/js/submit-review":return import(/* webpackChunkName: "Magento_Review/js/submit-review" */ "Magento_Review/js/submit-review");
case "Magento_Review/js/process-reviews":return import(/* webpackChunkName: "Magento_Review/js/process-reviews" */ "Magento_Review/js/process-reviews");
case "Magento_Catalog/js/product/view/provider":return import(/* webpackChunkName: "Magento_Catalog/js/product/view/provider" */ "Magento_Catalog/js/product/view/provider");
case "Magento_InstantPurchase/js/view/instant-purchase":return import(/* webpackChunkName: "Magento_InstantPurchase/js/view/instant-purchase" */ "Magento_InstantPurchase/js/view/instant-purchase");
case "Magento_Review/js/view/review":return import(/* webpackChunkName: "Magento_Review/js/view/review" */ "Magento_Review/js/view/review");
case "fotorama/fotorama":return import(/* webpackChunkName: "fotorama/fotorama" */ "fotorama/fotorama");
case "text!mage/gallery/gallery.html":return import(/* webpackChunkName: "text!mage/gallery/gallery.html" */ "text!mage/gallery/gallery.html");
case "text!Magento_InstantPurchase/template/confirmation.html":return import(/* webpackChunkName: "text!Magento_InstantPurchase/template/confirmation.html" */ "text!Magento_InstantPurchase/template/confirmation.html");
case "text!Magento_InstantPurchase/template/instant-purchase.html":return import(/* webpackChunkName: "text!Magento_InstantPurchase/template/instant-purchase.html" */ "text!Magento_InstantPurchase/template/instant-purchase.html");
case "text!Magento_Catalog/template/product/image_with_borders.html":return import(/* webpackChunkName: "text!Magento_Catalog/template/product/image_with_borders.html" */ "text!Magento_Catalog/template/product/image_with_borders.html");
case "Magento_CatalogSearch/js/search-terms-log":return import(/* webpackChunkName: "Magento_CatalogSearch/js/search-terms-log" */ "Magento_CatalogSearch/js/search-terms-log");
case "Magento_Dhl/js/view/shipping-rates-validation":return import(/* webpackChunkName: "Magento_Dhl/js/view/shipping-rates-validation" */ "Magento_Dhl/js/view/shipping-rates-validation");
case "Magento_GiftMessage/js/view/gift-message":return import(/* webpackChunkName: "Magento_GiftMessage/js/view/gift-message" */ "Magento_GiftMessage/js/view/gift-message");
case "Magento_Dhl/js/model/shipping-rates-validator":return import(/* webpackChunkName: "Magento_Dhl/js/model/shipping-rates-validator" */ "Magento_Dhl/js/model/shipping-rates-validator");
case "Magento_Dhl/js/model/shipping-rates-validation-rules":return import(/* webpackChunkName: "Magento_Dhl/js/model/shipping-rates-validation-rules" */ "Magento_Dhl/js/model/shipping-rates-validation-rules");
case "Magento_GiftMessage/js/model/gift-message":return import(/* webpackChunkName: "Magento_GiftMessage/js/model/gift-message" */ "Magento_GiftMessage/js/model/gift-message");
case "Magento_GiftMessage/js/model/gift-options":return import(/* webpackChunkName: "Magento_GiftMessage/js/model/gift-options" */ "Magento_GiftMessage/js/model/gift-options");
case "Magento_GiftMessage/js/action/gift-options":return import(/* webpackChunkName: "Magento_GiftMessage/js/action/gift-options" */ "Magento_GiftMessage/js/action/gift-options");
case "Magento_Fedex/js/view/shipping-rates-validation":return import(/* webpackChunkName: "Magento_Fedex/js/view/shipping-rates-validation" */ "Magento_Fedex/js/view/shipping-rates-validation");
case "Magento_GiftMessage/js/model/url-builder":return import(/* webpackChunkName: "Magento_GiftMessage/js/model/url-builder" */ "Magento_GiftMessage/js/model/url-builder");
case "text!Magento_GiftMessage/template/gift-message-item-level.html":return import(/* webpackChunkName: "text!Magento_GiftMessage/template/gift-message-item-level.html" */ "text!Magento_GiftMessage/template/gift-message-item-level.html");
case "Magento_Fedex/js/model/shipping-rates-validator":return import(/* webpackChunkName: "Magento_Fedex/js/model/shipping-rates-validator" */ "Magento_Fedex/js/model/shipping-rates-validator");
case "Magento_Fedex/js/model/shipping-rates-validation-rules":return import(/* webpackChunkName: "Magento_Fedex/js/model/shipping-rates-validation-rules" */ "Magento_Fedex/js/model/shipping-rates-validation-rules");
case "Magento_OfflineShipping/js/view/shipping-rates-validation/freeshipping":return import(/* webpackChunkName: "Magento_OfflineShipping/js/view/shipping-rates-validation/freeshipping" */ "Magento_OfflineShipping/js/view/shipping-rates-validation/freeshipping");
case "Magento_OfflineShipping/js/model/shipping-rates-validator/freeshipping":return import(/* webpackChunkName: "Magento_OfflineShipping/js/model/shipping-rates-validator/freeshipping" */ "Magento_OfflineShipping/js/model/shipping-rates-validator/freeshipping");
case "Magento_OfflineShipping/js/model/shipping-rates-validation-rules/freeshipping":return import(/* webpackChunkName: "Magento_OfflineShipping/js/model/shipping-rates-validation-rules/freeshipping" */ "Magento_OfflineShipping/js/model/shipping-rates-validation-rules/freeshipping");
case "Magento_SalesRule/js/model/coupon":return import(/* webpackChunkName: "Magento_SalesRule/js/model/coupon" */ "Magento_SalesRule/js/model/coupon");
case "text!Magento_GiftMessage/template/gift-message.html":return import(/* webpackChunkName: "text!Magento_GiftMessage/template/gift-message.html" */ "text!Magento_GiftMessage/template/gift-message.html");
case "Magento_OfflineShipping/js/view/shipping-rates-validation/flatrate":return import(/* webpackChunkName: "Magento_OfflineShipping/js/view/shipping-rates-validation/flatrate" */ "Magento_OfflineShipping/js/view/shipping-rates-validation/flatrate");
case "Magento_OfflineShipping/js/model/shipping-rates-validator/flatrate":return import(/* webpackChunkName: "Magento_OfflineShipping/js/model/shipping-rates-validator/flatrate" */ "Magento_OfflineShipping/js/model/shipping-rates-validator/flatrate");
case "Magento_OfflineShipping/js/model/shipping-rates-validation-rules/flatrate":return import(/* webpackChunkName: "Magento_OfflineShipping/js/model/shipping-rates-validation-rules/flatrate" */ "Magento_OfflineShipping/js/model/shipping-rates-validation-rules/flatrate");
case "Magento_OfflineShipping/js/view/shipping-rates-validation/tablerate":return import(/* webpackChunkName: "Magento_OfflineShipping/js/view/shipping-rates-validation/tablerate" */ "Magento_OfflineShipping/js/view/shipping-rates-validation/tablerate");
case "Magento_OfflineShipping/js/model/shipping-rates-validator/tablerate":return import(/* webpackChunkName: "Magento_OfflineShipping/js/model/shipping-rates-validator/tablerate" */ "Magento_OfflineShipping/js/model/shipping-rates-validator/tablerate");
case "Magento_OfflineShipping/js/model/shipping-rates-validation-rules/tablerate":return import(/* webpackChunkName: "Magento_OfflineShipping/js/model/shipping-rates-validation-rules/tablerate" */ "Magento_OfflineShipping/js/model/shipping-rates-validation-rules/tablerate");
case "Magento_Ups/js/view/shipping-rates-validation":return import(/* webpackChunkName: "Magento_Ups/js/view/shipping-rates-validation" */ "Magento_Ups/js/view/shipping-rates-validation");
case "Magento_Ups/js/model/shipping-rates-validator":return import(/* webpackChunkName: "Magento_Ups/js/model/shipping-rates-validator" */ "Magento_Ups/js/model/shipping-rates-validator");
case "Magento_Ups/js/model/shipping-rates-validation-rules":return import(/* webpackChunkName: "Magento_Ups/js/model/shipping-rates-validation-rules" */ "Magento_Ups/js/model/shipping-rates-validation-rules");
case "Magento_Usps/js/view/shipping-rates-validation":return import(/* webpackChunkName: "Magento_Usps/js/view/shipping-rates-validation" */ "Magento_Usps/js/view/shipping-rates-validation");
case "Magento_SalesRule/js/view/summary/discount":return import(/* webpackChunkName: "Magento_SalesRule/js/view/summary/discount" */ "Magento_SalesRule/js/view/summary/discount");
case "Magento_Usps/js/model/shipping-rates-validator":return import(/* webpackChunkName: "Magento_Usps/js/model/shipping-rates-validator" */ "Magento_Usps/js/model/shipping-rates-validator");
case "Magento_Usps/js/model/shipping-rates-validation-rules":return import(/* webpackChunkName: "Magento_Usps/js/model/shipping-rates-validation-rules" */ "Magento_Usps/js/model/shipping-rates-validation-rules");
case "Magento_Ui/js/form/element/abstract":return import(/* webpackChunkName: "Magento_Ui/js/form/element/abstract" */ "Magento_Ui/js/form/element/abstract");
case "Magento_Ui/js/lib/validation/validator":return import(/* webpackChunkName: "Magento_Ui/js/lib/validation/validator" */ "Magento_Ui/js/lib/validation/validator");
case "Magento_Ui/js/lib/validation/rules":return import(/* webpackChunkName: "Magento_Ui/js/lib/validation/rules" */ "Magento_Ui/js/lib/validation/rules");
case "Magento_Ui/js/lib/validation/utils":return import(/* webpackChunkName: "Magento_Ui/js/lib/validation/utils" */ "Magento_Ui/js/lib/validation/utils");
case "Magento_Ui/js/form/element/select":return import(/* webpackChunkName: "Magento_Ui/js/form/element/select" */ "Magento_Ui/js/form/element/select");
case "Magento_Ui/js/form/element/region":return import(/* webpackChunkName: "Magento_Ui/js/form/element/region" */ "Magento_Ui/js/form/element/region");
case "text!ui/template/form/field.html":return import(/* webpackChunkName: "text!ui/template/form/field.html" */ "text!ui/template/form/field.html");
case "text!ui/template/form/element/select.html":return import(/* webpackChunkName: "text!ui/template/form/element/select.html" */ "text!ui/template/form/element/select.html");
case "text!ui/template/form/element/input.html":return import(/* webpackChunkName: "text!ui/template/form/element/input.html" */ "text!ui/template/form/element/input.html");
case "Magento_Ui/js/form/element/post-code":return import(/* webpackChunkName: "Magento_Ui/js/form/element/post-code" */ "Magento_Ui/js/form/element/post-code");
case "Magento_Ui/js/form/components/group":return import(/* webpackChunkName: "Magento_Ui/js/form/components/group" */ "Magento_Ui/js/form/components/group");
case "Magento_SalesRule/js/action/set-coupon-code":return import(/* webpackChunkName: "Magento_SalesRule/js/action/set-coupon-code" */ "Magento_SalesRule/js/action/set-coupon-code");
case "Magento_SalesRule/js/action/cancel-coupon":return import(/* webpackChunkName: "Magento_SalesRule/js/action/cancel-coupon" */ "Magento_SalesRule/js/action/cancel-coupon");
case "Magento_Shipping/js/model/config":return import(/* webpackChunkName: "Magento_Shipping/js/model/config" */ "Magento_Shipping/js/model/config");
case "text!Magento_SalesRule/template/summary/discount.html":return import(/* webpackChunkName: "text!Magento_SalesRule/template/summary/discount.html" */ "text!Magento_SalesRule/template/summary/discount.html");
case "Mageplaza_SocialLogin/js/view/authentication":return import(/* webpackChunkName: "Mageplaza_SocialLogin/js/view/authentication" */ "Mageplaza_SocialLogin/js/view/authentication");
case "Mageplaza_Smtp/js/model/address-on-change":return import(/* webpackChunkName: "Mageplaza_Smtp/js/model/address-on-change" */ "Mageplaza_Smtp/js/model/address-on-change");
case "Mageplaza_Smtp/js/action/send-address":return import(/* webpackChunkName: "Mageplaza_Smtp/js/action/send-address" */ "Mageplaza_Smtp/js/action/send-address");
case "Mageplaza_Smtp/js/model/resource-url-manager":return import(/* webpackChunkName: "Mageplaza_Smtp/js/model/resource-url-manager" */ "Mageplaza_Smtp/js/model/resource-url-manager");
case "text!ui/template/form/element/helper/tooltip.html":return import(/* webpackChunkName: "text!ui/template/form/element/helper/tooltip.html" */ "text!ui/template/form/element/helper/tooltip.html");
case "text!ui/template/group/group.html":return import(/* webpackChunkName: "text!ui/template/group/group.html" */ "text!ui/template/group/group.html");
case "Magento_Sales/js/view/last-ordered-items":return import(/* webpackChunkName: "Magento_Sales/js/view/last-ordered-items" */ "Magento_Sales/js/view/last-ordered-items");
case "mage/calendar":return import(/* webpackChunkName: "mage/calendar" */ "mage/calendar");
case "jquery-ui-modules/datepicker":return import(/* webpackChunkName: "jquery/ui-modules/widgets/datepicker" */ "jquery/ui-modules/widgets/datepicker");
case "jquery-ui-modules/timepicker":return import(/* webpackChunkName: "jquery/timepicker" */ "jquery/timepicker");
case "jquery-ui-modules/slider":return import(/* webpackChunkName: "jquery/ui-modules/widgets/selectmenu" */ "jquery/ui-modules/widgets/selectmenu");
case "offcanvasDialog":return import(/* webpackChunkName: "Magento_Theme/js/mage/offcanvas" */ "Magento_Theme/js/mage/offcanvas");
case "jquery-ui-modules/accordion":return import(/* webpackChunkName: "jquery/ui-modules/widgets/accordion" */ "jquery/ui-modules/widgets/accordion");
case "jquery-ui-modules/autocomplete":return import(/* webpackChunkName: "jquery/ui-modules/widgets/autocomplete" */ "jquery/ui-modules/widgets/autocomplete");
case "jquery-ui-modules/draggable":return import(/* webpackChunkName: "jquery/ui-modules/widgets/draggable" */ "jquery/ui-modules/widgets/draggable");
case "jquery-ui-modules/droppable":return import(/* webpackChunkName: "jquery/ui-modules/widgets/droppable" */ "jquery/ui-modules/widgets/droppable");
case "jquery-ui-modules/effect-bounce":return import(/* webpackChunkName: "jquery/ui-modules/effects/effect-bounce" */ "jquery/ui-modules/effects/effect-bounce");
case "jquery-ui-modules/effect-clip":return import(/* webpackChunkName: "jquery/ui-modules/effects/effect-clip" */ "jquery/ui-modules/effects/effect-clip");
case "jquery-ui-modules/effect-drop":return import(/* webpackChunkName: "jquery/ui-modules/effects/effect-drop" */ "jquery/ui-modules/effects/effect-drop");
case "jquery-ui-modules/effect-explode":return import(/* webpackChunkName: "jquery/ui-modules/effects/effect-explode" */ "jquery/ui-modules/effects/effect-explode");
case "jquery-ui-modules/effect-fold":return import(/* webpackChunkName: "jquery/ui-modules/effects/effect-fold" */ "jquery/ui-modules/effects/effect-fold");
case "jquery-ui-modules/effect-highlight":return import(/* webpackChunkName: "jquery/ui-modules/effects/effect-highlight" */ "jquery/ui-modules/effects/effect-highlight");
case "jquery-ui-modules/effect-scale":return import(/* webpackChunkName: "jquery/ui-modules/effects/effect-scale" */ "jquery/ui-modules/effects/effect-scale");
case "jquery-ui-modules/effect-pulsate":return import(/* webpackChunkName: "jquery/ui-modules/effects/effect-pulsate" */ "jquery/ui-modules/effects/effect-pulsate");
case "jquery-ui-modules/effect-shake":return import(/* webpackChunkName: "jquery/ui-modules/effects/effect-shake" */ "jquery/ui-modules/effects/effect-shake");
case "jquery-ui-modules/effect-slide":return import(/* webpackChunkName: "jquery/ui-modules/effects/effect-slide" */ "jquery/ui-modules/effects/effect-slide");
case "jquery-ui-modules/effect-transfer":return import(/* webpackChunkName: "jquery/ui-modules/effects/effect-transfer" */ "jquery/ui-modules/effects/effect-transfer");
case "jquery-ui-modules/progressbar":return import(/* webpackChunkName: "jquery/ui-modules/widgets/progressbar" */ "jquery/ui-modules/widgets/progressbar");
case "jquery-ui-modules/selectable":return import(/* webpackChunkName: "jquery/ui-modules/widgets/selectable" */ "jquery/ui-modules/widgets/selectable");
case "jquery-ui-modules/sortable":return import(/* webpackChunkName: "jquery/ui-modules/widgets/sortable" */ "jquery/ui-modules/widgets/sortable");
case "jquery-ui-modules/spinner":return import(/* webpackChunkName: "jquery/ui-modules/widgets/spinner" */ "jquery/ui-modules/widgets/spinner");
case "jquery-ui-modules/tabs":return import(/* webpackChunkName: "jquery/ui-modules/widgets/tabs" */ "jquery/ui-modules/widgets/tabs");
case "jquery-ui-modules/tooltip":return import(/* webpackChunkName: "jquery/ui-modules/widgets/tooltip" */ "jquery/ui-modules/widgets/tooltip");
case "Magento_Theme/js/view/messages-mixin":return import(/* webpackChunkName: "Magento_Theme/js/view/messages-mixin" */ "Magento_Theme/js/view/messages-mixin");
case "YLX_AjaxLayer/js/action/submit-filter":return import(/* webpackChunkName: "YLX_AjaxLayer/js/action/submit-filter" */ "YLX_AjaxLayer/js/action/submit-filter");
case "YLX_AjaxLayer/js/model/loader":return import(/* webpackChunkName: "YLX_AjaxLayer/js/model/loader" */ "YLX_AjaxLayer/js/model/loader");
case "YLX_AjaxLayer/js/range-slider-widget-mixin":return import(/* webpackChunkName: "YLX_AjaxLayer/js/range-slider-widget-mixin" */ "YLX_AjaxLayer/js/range-slider-widget-mixin");
case "Smile_ElasticsuiteCatalog/js/attribute-filter":return import(/* webpackChunkName: "Smile_ElasticsuiteCatalog/js/attribute-filter" */ "Smile_ElasticsuiteCatalog/js/attribute-filter");
case "text!Smile_ElasticsuiteCatalog/template/attribute-filter.html":return import(/* webpackChunkName: "text!Smile_ElasticsuiteCatalog/template/attribute-filter.html" */ "text!Smile_ElasticsuiteCatalog/template/attribute-filter.html");
case "text!Smile_ElasticsuiteCore/template/autocomplete/term.html":return import(/* webpackChunkName: "text!Smile_ElasticsuiteCore/template/autocomplete/term.html" */ "text!Smile_ElasticsuiteCore/template/autocomplete/term.html");
case "text!Smile_ElasticsuiteCatalog/template/autocomplete/product.html":return import(/* webpackChunkName: "text!Smile_ElasticsuiteCatalog/template/autocomplete/product.html" */ "text!Smile_ElasticsuiteCatalog/template/autocomplete/product.html");
case "text!Smile_ElasticsuiteCatalog/template/autocomplete/category.html":return import(/* webpackChunkName: "text!Smile_ElasticsuiteCatalog/template/autocomplete/category.html" */ "text!Smile_ElasticsuiteCatalog/template/autocomplete/category.html");
case "text!Smile_ElasticsuiteCatalog/template/autocomplete/product-attribute.html":return import(/* webpackChunkName: "text!Smile_ElasticsuiteCatalog/template/autocomplete/product-attribute.html" */ "text!Smile_ElasticsuiteCatalog/template/autocomplete/product-attribute.html");
case "text!Smile_ElasticsuiteCms/template/autocomplete/cms.html":return import(/* webpackChunkName: "text!Smile_ElasticsuiteCms/template/autocomplete/cms.html" */ "text!Smile_ElasticsuiteCms/template/autocomplete/cms.html");
case "text!YLX_ElasticsuiteBlog/template/autocomplete/post.html":return import(/* webpackChunkName: "text!YLX_ElasticsuiteBlog/template/autocomplete/post.html" */ "text!YLX_ElasticsuiteBlog/template/autocomplete/post.html");
case "Smile_ElasticsuiteCatalog/js/autocomplete/product-attribute":return import(/* webpackChunkName: "Smile_ElasticsuiteCatalog/js/autocomplete/product-attribute" */ "Smile_ElasticsuiteCatalog/js/autocomplete/product-attribute");
case "Magento_Catalog/js/related-products-mixin":return import(/* webpackChunkName: "Magento_Catalog/js/related-products-mixin" */ "Magento_Catalog/js/related-products-mixin");
case "bootstrap/js/src/carousel":return import(/* webpackChunkName: "bootstrap/js/src/carousel" */ "bootstrap/js/src/carousel");
case "js/carousel":return import(/* webpackChunkName: "js/carousel" */ "js/carousel");
case "YLX_Search/js/form-mini":return import(/* webpackChunkName: "YLX_Search/js/form-mini" */ "YLX_Search/js/form-mini");
case "Smile_ElasticsuiteCore/js/form-mini":return import(/* webpackChunkName: "Smile_ElasticsuiteCore/js/form-mini" */ "Smile_ElasticsuiteCore/js/form-mini");
case "bootstrap-input-spinner/src/bootstrap-input-spinner":return import(/* webpackChunkName: "bootstrap-input-spinner/src/bootstrap-input-spinner" */ "bootstrap-input-spinner/src/bootstrap-input-spinner");
case "Magento_Catalog/js/upsell-products-mixin":return import(/* webpackChunkName: "Magento_Catalog/js/upsell-products-mixin" */ "Magento_Catalog/js/upsell-products-mixin");
case "Smile_ElasticsuiteCatalog/js/mouse":return import(/* webpackChunkName: "Smile_ElasticsuiteCatalog/js/mouse" */ "Smile_ElasticsuiteCatalog/js/mouse");
case "jquery/metadata":return import(/* webpackChunkName: "jquery/jquery.metadata" */ "jquery/jquery.metadata");
case "ylxGtmDatalayer":return import(/* webpackChunkName: "YLX_GoogleTagManager/js/datalayer" */ "YLX_GoogleTagManager/js/datalayer");
case "Magento_Wishlist/js/view/wishlist":return import(/* webpackChunkName: "Magento_Wishlist/js/view/wishlist" */ "Magento_Wishlist/js/view/wishlist");
case "Magento_ReCaptchaWebapiUi/js/jquery-mixin":return import(/* webpackChunkName: "Magento_ReCaptchaWebapiUi/js/jquery-mixin" */ "Magento_ReCaptchaWebapiUi/js/jquery-mixin");
case "Magento_ReCaptchaFrontendUi/js/ui-messages-mixin":return import(/* webpackChunkName: "Magento_ReCaptchaFrontendUi/js/ui-messages-mixin" */ "Magento_ReCaptchaFrontendUi/js/ui-messages-mixin");
case "Magento_ReCaptchaFrontendUi/js/registry":return import(/* webpackChunkName: "Magento_ReCaptchaFrontendUi/js/registry" */ "Magento_ReCaptchaFrontendUi/js/registry");
case "Magento_PageBuilder/js/widget-initializer":return import(/* webpackChunkName: "Magento_PageBuilder/js/widget-initializer" */ "Magento_PageBuilder/js/widget-initializer");
case "Magento_Customer/js/section-config":return import(/* webpackChunkName: "Magento_Customer/js/section-config" */ "Magento_Customer/js/section-config");
case "Magento_Customer/js/block-submit-on-send":return import(/* webpackChunkName: "Magento_Customer/js/block-submit-on-send" */ "Magento_Customer/js/block-submit-on-send");
case "Magento_Customer/js/customer-data":return import(/* webpackChunkName: "Magento_Customer/js/customer-data" */ "Magento_Customer/js/customer-data");
case "Magento_Persistent/js/view/customer-data-mixin":return import(/* webpackChunkName: "Magento_Persistent/js/view/customer-data-mixin" */ "Magento_Persistent/js/view/customer-data-mixin");
case "tiny-slider":return import(/* webpackChunkName: "tiny-slider" */ "tiny-slider");
case "jquery/ui-modules/version":return import(/* webpackChunkName: "jquery/ui-modules/version" */ "jquery/ui-modules/version");
case "jquery/ui-modules/widgets/tabs":return import(/* webpackChunkName: "jquery/ui-modules/widgets/tabs" */ "jquery/ui-modules/widgets/tabs");
case "jquery/ui-modules/data":return import(/* webpackChunkName: "jquery/ui-modules/data" */ "jquery/ui-modules/data");
case "jquery/ui-modules/disable-selection":return import(/* webpackChunkName: "jquery/ui-modules/disable-selection" */ "jquery/ui-modules/disable-selection");
case "jquery/ui-modules/focusable":return import(/* webpackChunkName: "jquery/ui-modules/focusable" */ "jquery/ui-modules/focusable");
case "jquery/ui-modules/form":return import(/* webpackChunkName: "jquery/ui-modules/form" */ "jquery/ui-modules/form");
case "jquery/ui-modules/ie":return import(/* webpackChunkName: "jquery/ui-modules/ie" */ "jquery/ui-modules/ie");
case "jquery/ui-modules/keycode":return import(/* webpackChunkName: "jquery/ui-modules/keycode" */ "jquery/ui-modules/keycode");
case "jquery/ui-modules/labels":return import(/* webpackChunkName: "jquery/ui-modules/labels" */ "jquery/ui-modules/labels");
case "jquery/ui-modules/jquery-patch":return import(/* webpackChunkName: "jquery/ui-modules/jquery-patch" */ "jquery/ui-modules/jquery-patch");
case "jquery/ui-modules/plugin":return import(/* webpackChunkName: "jquery/ui-modules/plugin" */ "jquery/ui-modules/plugin");
case "jquery/ui-modules/safe-active-element":return import(/* webpackChunkName: "jquery/ui-modules/safe-active-element" */ "jquery/ui-modules/safe-active-element");
case "jquery/ui-modules/safe-blur":return import(/* webpackChunkName: "jquery/ui-modules/safe-blur" */ "jquery/ui-modules/safe-blur");
case "jquery/ui-modules/scroll-parent":return import(/* webpackChunkName: "jquery/ui-modules/scroll-parent" */ "jquery/ui-modules/scroll-parent");
case "jquery/ui-modules/tabbable":return import(/* webpackChunkName: "jquery/ui-modules/tabbable" */ "jquery/ui-modules/tabbable");
case "jquery/ui-modules/unique-id":return import(/* webpackChunkName: "jquery/ui-modules/unique-id" */ "jquery/ui-modules/unique-id");
case "js-storage/js.storage":return import(/* webpackChunkName: "js-storage/js.storage" */ "js-storage/js.storage");
case "jquery/z-index":return import(/* webpackChunkName: "jquery/z-index" */ "jquery/z-index");
case "js-cookie/cookie-wrapper":return import(/* webpackChunkName: "js-cookie/cookie-wrapper" */ "js-cookie/cookie-wrapper");
case "jquery/ui-modules/position":return import(/* webpackChunkName: "jquery/ui-modules/position" */ "jquery/ui-modules/position");
case "jquery/ui-modules/widget":return import(/* webpackChunkName: "jquery/ui-modules/widget" */ "jquery/ui-modules/widget");
case "js-cookie/js.cookie":return import(/* webpackChunkName: "js-cookie/js.cookie" */ "js-cookie/js.cookie");
case "Magento_InventorySwatchesFrontendUi/js/swatch-renderer":return import(/* webpackChunkName: "Magento_InventorySwatchesFrontendUi/js/swatch-renderer" */ "Magento_InventorySwatchesFrontendUi/js/swatch-renderer");
case "configurableVariationQty":return import(/* webpackChunkName: "Magento_InventoryConfigurableProductFrontendUi/js/configurable-variation-qty" */ "Magento_InventoryConfigurableProductFrontendUi/js/configurable-variation-qty");
case "vimeoWrapper":return import(/* webpackChunkName: "vimeo/vimeo-wrapper" */ "vimeo/vimeo-wrapper");
case "vimeo":return import(/* webpackChunkName: "vimeo/player" */ "vimeo/player");
case "Magento_Bundle_requirejs-config":return import(/* webpackChunkName: "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_Bundle/requirejs-config.js" */ "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_Bundle/requirejs-config.js");
case "Magento_Captcha_requirejs-config":return import(/* webpackChunkName: "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_Captcha/requirejs-config.js" */ "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_Captcha/requirejs-config.js");
case "Magento_CardinalCommerce_requirejs-config":return import(/* webpackChunkName: "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_CardinalCommerce/requirejs-config.js" */ "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_CardinalCommerce/requirejs-config.js");
case "Magento_CatalogSearch_requirejs-config":return import(/* webpackChunkName: "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_CatalogSearch/requirejs-config.js" */ "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_CatalogSearch/requirejs-config.js");
case "Magento_Catalog_requirejs-config":return import(/* webpackChunkName: "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_Catalog/requirejs-config.js" */ "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_Catalog/requirejs-config.js");
case "Magento_CheckoutAgreements_requirejs-config":return import(/* webpackChunkName: "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_CheckoutAgreements/requirejs-config.js" */ "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_CheckoutAgreements/requirejs-config.js");
case "Magento_Checkout_requirejs-config":return import(/* webpackChunkName: "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_Checkout/requirejs-config.js" */ "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_Checkout/requirejs-config.js");
case "Magento_ConfigurableProduct_requirejs-config":return import(/* webpackChunkName: "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_ConfigurableProduct/requirejs-config.js" */ "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_ConfigurableProduct/requirejs-config.js");
case "Magento_Cookie_requirejs-config":return import(/* webpackChunkName: "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_Cookie/requirejs-config.js" */ "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_Cookie/requirejs-config.js");
case "Magento_Customer_requirejs-config":return import(/* webpackChunkName: "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_Customer/requirejs-config.js" */ "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_Customer/requirejs-config.js");
case "Magento_Directory_requirejs-config":return import(/* webpackChunkName: "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_Directory/requirejs-config.js" */ "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_Directory/requirejs-config.js");
case "Magento_Downloadable_requirejs-config":return import(/* webpackChunkName: "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_Downloadable/requirejs-config.js" */ "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_Downloadable/requirejs-config.js");
case "Magento_GiftMessage_requirejs-config":return import(/* webpackChunkName: "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_GiftMessage/requirejs-config.js" */ "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_GiftMessage/requirejs-config.js");
case "Magento_InventoryConfigurableProductFrontendUi_requirejs-config":return import(/* webpackChunkName: "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_InventoryConfigurableProductFrontendUi/requirejs-config.js" */ "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_InventoryConfigurableProductFrontendUi/requirejs-config.js");
case "Magento_InventoryInStorePickupFrontend_requirejs-config":return import(/* webpackChunkName: "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_InventoryInStorePickupFrontend/requirejs-config.js" */ "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_InventoryInStorePickupFrontend/requirejs-config.js");
case "Magento_InventorySwatchesFrontendUi_requirejs-config":return import(/* webpackChunkName: "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_InventorySwatchesFrontendUi/requirejs-config.js" */ "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_InventorySwatchesFrontendUi/requirejs-config.js");
case "Magento_Msrp_requirejs-config":return import(/* webpackChunkName: "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_Msrp/requirejs-config.js" */ "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_Msrp/requirejs-config.js");
case "Magento_Multishipping_requirejs-config":return import(/* webpackChunkName: "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_Multishipping/requirejs-config.js" */ "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_Multishipping/requirejs-config.js");
case "Magento_Newsletter_requirejs-config":return import(/* webpackChunkName: "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_Newsletter/requirejs-config.js" */ "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_Newsletter/requirejs-config.js");
case "Magento_PageBuilder_requirejs-config":return import(/* webpackChunkName: "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_PageBuilder/requirejs-config.js" */ "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_PageBuilder/requirejs-config.js");
case "Magento_PageCache_requirejs-config":return import(/* webpackChunkName: "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_PageCache/requirejs-config.js" */ "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_PageCache/requirejs-config.js");
case "Magento_Payment_requirejs-config":return import(/* webpackChunkName: "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_Payment/requirejs-config.js" */ "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_Payment/requirejs-config.js");
case "Magento_PaypalCaptcha_requirejs-config":return import(/* webpackChunkName: "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_PaypalCaptcha/requirejs-config.js" */ "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_PaypalCaptcha/requirejs-config.js");
case "Magento_Paypal_requirejs-config":return import(/* webpackChunkName: "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_Paypal/requirejs-config.js" */ "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_Paypal/requirejs-config.js");
case "Magento_Persistent_requirejs-config":return import(/* webpackChunkName: "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_Persistent/requirejs-config.js" */ "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_Persistent/requirejs-config.js");
case "Magento_ProductVideo_requirejs-config":return import(/* webpackChunkName: "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_ProductVideo/requirejs-config.js" */ "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_ProductVideo/requirejs-config.js");
case "Magento_ReCaptchaCheckout_requirejs-config":return import(/* webpackChunkName: "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_ReCaptchaCheckout/requirejs-config.js" */ "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_ReCaptchaCheckout/requirejs-config.js");
case "Magento_ReCaptchaFrontendUi_requirejs-config":return import(/* webpackChunkName: "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_ReCaptchaFrontendUi/requirejs-config.js" */ "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_ReCaptchaFrontendUi/requirejs-config.js");
case "Magento_ReCaptchaPaypal_requirejs-config":return import(/* webpackChunkName: "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_ReCaptchaPaypal/requirejs-config.js" */ "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_ReCaptchaPaypal/requirejs-config.js");
case "Magento_ReCaptchaWebapiUi_requirejs-config":return import(/* webpackChunkName: "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_ReCaptchaWebapiUi/requirejs-config.js" */ "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_ReCaptchaWebapiUi/requirejs-config.js");
case "Magento_Reports_requirejs-config":return import(/* webpackChunkName: "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_Reports/requirejs-config.js" */ "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_Reports/requirejs-config.js");
case "Magento_SalesRule_requirejs-config":return import(/* webpackChunkName: "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_SalesRule/requirejs-config.js" */ "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_SalesRule/requirejs-config.js");
case "Magento_Sales_requirejs-config":return import(/* webpackChunkName: "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_Sales/requirejs-config.js" */ "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_Sales/requirejs-config.js");
case "Magento_Search_requirejs-config":return import(/* webpackChunkName: "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_Search/requirejs-config.js" */ "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_Search/requirejs-config.js");
case "Magento_Security_requirejs-config":return import(/* webpackChunkName: "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_Security/requirejs-config.js" */ "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_Security/requirejs-config.js");
case "Magento_SendFriend_requirejs-config":return import(/* webpackChunkName: "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_SendFriend/requirejs-config.js" */ "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_SendFriend/requirejs-config.js");
case "Magento_Swatches_requirejs-config":return import(/* webpackChunkName: "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_Swatches/requirejs-config.js" */ "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_Swatches/requirejs-config.js");
case "Magento_Theme_requirejs-config":return import(/* webpackChunkName: "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_Theme/requirejs-config.js" */ "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_Theme/requirejs-config.js");
case "Magento_Translation_requirejs-config":return import(/* webpackChunkName: "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_Translation/requirejs-config.js" */ "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_Translation/requirejs-config.js");
case "Magento_Ui_requirejs-config":return import(/* webpackChunkName: "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_Ui/requirejs-config.js" */ "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_Ui/requirejs-config.js");
case "Magento_Weee_requirejs-config":return import(/* webpackChunkName: "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_Weee/requirejs-config.js" */ "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_Weee/requirejs-config.js");
case "Magento_Wishlist_requirejs-config":return import(/* webpackChunkName: "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_Wishlist/requirejs-config.js" */ "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Magento_Wishlist/requirejs-config.js");
case "Mageplaza_Core_requirejs-config":return import(/* webpackChunkName: "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Mageplaza_Core/requirejs-config.js" */ "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Mageplaza_Core/requirejs-config.js");
case "Mageplaza_GoogleRecaptcha_requirejs-config":return import(/* webpackChunkName: "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Mageplaza_GoogleRecaptcha/requirejs-config.js" */ "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Mageplaza_GoogleRecaptcha/requirejs-config.js");
case "Mageplaza_Smtp_requirejs-config":return import(/* webpackChunkName: "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Mageplaza_Smtp/requirejs-config.js" */ "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Mageplaza_Smtp/requirejs-config.js");
case "Mageplaza_SocialLogin_requirejs-config":return import(/* webpackChunkName: "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Mageplaza_SocialLogin/requirejs-config.js" */ "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Mageplaza_SocialLogin/requirejs-config.js");
case "Mirasvit_Affiliate_requirejs-config":return import(/* webpackChunkName: "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Mirasvit_Affiliate/requirejs-config.js" */ "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Mirasvit_Affiliate/requirejs-config.js");
case "PayPal_Braintree_requirejs-config":return import(/* webpackChunkName: "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/PayPal_Braintree/requirejs-config.js" */ "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/PayPal_Braintree/requirejs-config.js");
case "Smile_ElasticsuiteCatalog_requirejs-config":return import(/* webpackChunkName: "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Smile_ElasticsuiteCatalog/requirejs-config.js" */ "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Smile_ElasticsuiteCatalog/requirejs-config.js");
case "Smile_ElasticsuiteCore_requirejs-config":return import(/* webpackChunkName: "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Smile_ElasticsuiteCore/requirejs-config.js" */ "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/Smile_ElasticsuiteCore/requirejs-config.js");
case "StripeIntegration_Payments_requirejs-config":return import(/* webpackChunkName: "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/StripeIntegration_Payments/requirejs-config.js" */ "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/StripeIntegration_Payments/requirejs-config.js");
case "YLX_AjaxLayer_requirejs-config":return import(/* webpackChunkName: "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/YLX_AjaxLayer/requirejs-config.js" */ "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/YLX_AjaxLayer/requirejs-config.js");
case "YLX_GoogleTagManager_requirejs-config":return import(/* webpackChunkName: "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/YLX_GoogleTagManager/requirejs-config.js" */ "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/YLX_GoogleTagManager/requirejs-config.js");
case "YLX_Sales_requirejs-config":return import(/* webpackChunkName: "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/YLX_Sales/requirejs-config.js" */ "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/YLX_Sales/requirejs-config.js");
case "YLX_Search_requirejs-config":return import(/* webpackChunkName: "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/YLX_Search/requirejs-config.js" */ "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/YLX_Search/requirejs-config.js");
case "YLX_YlxTheme_requirejs-config":return import(/* webpackChunkName: "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/YLX_YlxTheme/requirejs-config.js" */ "/home/bsk/Projects/sites/demo-on.com/var/assets/js/site/YLX_YlxTheme/requirejs-config.js");
case "Magento_Theme/js/jquery/massive-fix":return import(/* webpackChunkName: "Magento_Theme/js/jquery/massive-fix" */ "Magento_Theme/js/jquery/massive-fix");
default:
console.log('component: ', component, 'not found!');break;}}